import styled, {css} from "styled-components"

export const Card = styled.div`
  background: #F6F6F6;
  padding: 32px;
  margin-bottom: 16px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
`

export const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
  
  ${props => props.highlighted && css`
    font-weight: 600;
    font-size: 42px;
    line-height: 42px;
  `}
`

export const Subtitle = styled.div`
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 90px;
`

export const Meta = styled.div`
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 32px;
  font-weight: 300;
  
  ${props => props.highlighted && css`
   width: 40%;
` }
`