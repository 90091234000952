import React from "react"
import { Link } from "gatsby"
import { Grid, Row, Col } from 'react-flexbox-grid';
import CaseStudyPreview from "../components/CaseStudyPreview"

import SEO from "../components/seo"

export default function SecondPage(props) {

  return (
    <div>
      <SEO title="Sudeep UX Design Case Studies" />
      <Grid>
        <Row>
          <Col xs={12}>
            <h1>Case Studies</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <CaseStudyPreview  highlighted
                               title="Sensibull"
                               subtitle="Options Trading Platform"
                               role="Co-Founder"
                               meta="Product designer wearing multiple hats(AKA unicorn Designer)"
            />
          </Col>
          <Col xs={12} sm={6}>
            <CaseStudyPreview title="TAB9" subtitle="Smart Compose for code" role="UI Design and Development" meta="Brand Identity and "/>
          </Col>
          <Col xs={12} sm={6}>
            <CaseStudyPreview title="SVG Encoder" subtitle="URL-encoder for SVG" role="UI Design and Development" meta="Product designer wearing multiple hats(AKA unicorn Designer)"/>
          </Col>
          <Col xs={12} sm={6}>
            <CaseStudyPreview title="Wealth Management" subtitle="qplum - Wealth Management Plans. AI Robo Advisor." role="Product Designer and UX Engineer" meta="--"/>
          </Col>
        </Row>
      </Grid>



    </div>
  );
}

