import PropTypes from "prop-types"
import React from "react"
import Button from "../Button"

import {Card, Title, Subtitle, Meta  } from './style'



const CaseStudyPreview = (props ) => (
  <Card >
    <Title highlighted={props.highlighted ? true: false}> {props.title}</Title>
    <Subtitle>{props.subtitle}</Subtitle>
    <Meta highlighted={props.highlighted ? true: false}>Role: {props.role}, {props.meta}</Meta>
    <Button ghost>View Case Study</Button>
  </Card>
)

CaseStudyPreview.propTypes = {
}

export default CaseStudyPreview
